/**
 * External dependencies.
 */
/* eslint-disable import/first */
import React, { useState } from 'react';
import { ApolloProvider } from '@apollo/client';

import { getVariableClient } from './client';
import { useCurrencyRate } from '../hooks/currency-rate';
import { AppProvider } from '../components/context/AppContext';
import { StoreContextProvider } from '../context/storeContext';
import AudioPlayer from '../components/podcast/AudioPlayer';
import { getUserDetailsQuery } from '../queries/get-user-details';
import 'aos/dist/aos.css';
import { useShopifyAPI } from '../hooks';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const AppContext = React.createContext([{}, () => {}]);

const DynamicProvider = ({ element }) => {
  const [getUserDetailsByAccessToken, data, error, loading] = useShopifyAPI();

  React.useEffect(() => {
    const getInitAOS = async () => {
      if (typeof window !== undefined) {
        /* eslint-disable import/first */

        const AOS = await import('aos');
        AOS.init({
          once: true,
        });
      }
    };
    getInitAOS();
  }, []);

  const [client, setClient] = useState(getVariableClient());
  const [currentUserDetails, setCurrentUserDetails] = useState();
  const [customerTokenDetails, setCustomerTokenDetails] = useState();

  const [currency, setCurrency] = useState('USD');
  const [language, setLanguage] = useState('BR');
  const [activeTabForPeopleSpecificPage, setActiveTabForPeopleSpecificPage] = useState('timeline');
  const [selectedPodCast, setSelectedPodcast] = useState(null);
  const [isAudioPause, SetIsAudioPause] = useState(true);
  const [selectedSeasonForTab, setSelectedSeason] = React.useState('season-1');

  const [isEventChecked, setIsEventChecked] = React.useState(true);
  const [donatingProjectId, setDonatingProjectId] = React.useState('');

  const [isPeopleChecked, setIsPeopleChecked] = React.useState(true);
  const [skipTime, setSkipTime] = React.useState('10');
  const [zoomValue, setZoomValue] = React.useState(100);
  const [currentChapterIndex, setCurrentChapterIndex] = React.useState(null);
  const currenciesRate = useCurrencyRate();
  const [scrolledDirection, setScrolledDirection] = useState(true);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [deviceIsTab, setDeviceStatus] = useState(false);

  const getAudioPlayer = () => {
    if (typeof window !== undefined) {
      return <AudioPlayer />;
    }
    return null;
  };

  React.useEffect(() => {
    if (window) {
      const accessToken = localStorage.getItem('shopifyAccessToken');
      const expiresAt = localStorage.getItem('shopifyAccessTokenExpiresAt');
      setCustomerTokenDetails({ accessToken, expiresAt });
    }
  }, []);

  React.useEffect(() => {
    if (customerTokenDetails?.accessToken) {
      getUserDetailsByAccessToken(
        `query {
    customer(customerAccessToken: "${customerTokenDetails.accessToken}") {
      id
      firstName
      lastName
      acceptsMarketing
      email
      phone
    }
  }`,
        {
          onSuccess: (res) => {
            setCurrentUserDetails(res.data);
          },
          onError: (error) => {
            setCurrentUserDetails(undefined);
            setCustomerTokenDetails(undefined);
            localStorage.removeItem('shopifyAccessToken');
            localStorage.removeItem('shopifyAccessTokenExpiresAt');
          },
        }
      );
    }
  }, [customerTokenDetails]);

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      window.onscroll = function (e) {
        setScrollPosition(window.scrollY);
        if (this.oldScroll > this.scrollY) {
          setScrolledDirection(false);
        } else {
          setScrolledDirection(true);
        }

        this.oldScroll = this.scrollY;
      };
      let deviceWidth = window.matchMedia && window.matchMedia('(max-width: 768px)');

      setDeviceStatus(deviceWidth.matches);
      window.onresize = () => {
        setDeviceStatus(deviceWidth.matches);
      };
    }
  }, [scrolledDirection]);

  return (
    <AppContext.Provider
      value={{
        currentChapterIndex,
        setCurrentChapterIndex,
        currency,
        setCurrency,
        language,
        setLanguage,
        currenciesRate,
        activeTabForPeopleSpecificPage,
        setActiveTabForPeopleSpecificPage,
        selectedPodCast,
        setSelectedPodcast,
        selectedSeasonForTab,
        setSelectedSeason,
        isAudioPause,
        SetIsAudioPause,
        isEventChecked,
        setIsEventChecked,
        isPeopleChecked,
        setIsPeopleChecked,
        skipTime,
        setSkipTime,
        zoomValue,
        setZoomValue,
        currentUserDetails,
        setCustomerTokenDetails,
        setCurrentUserDetails,
        donatingProjectId,
        setDonatingProjectId,
        scrolledDirection,
        deviceIsTab,
        scrollPosition,
      }}>
      <ApolloProvider client={client}>
        <StoreContextProvider>
          {element}
          {selectedPodCast && (
            <div className="wrapper wrapper-xl ">
              <div className="wrapper wrapper-xl pd-x-md pd-x-sm audio-player-container ">{getAudioPlayer()}</div>
            </div>
          )}
        </StoreContextProvider>
      </ApolloProvider>
    </AppContext.Provider>
  );
};

/**
 * Root Element which is a wrapper to the app.
 *
 * @param {Object} element App element.
 *
 * @return {*}
 */
export const wrapRootElement = ({ element }) => {
  return (
    <AppProvider>
      <ToastContainer autoClose={3000} closeOnClick pauseOnHover pauseOnFocusLoss limit={2} />
      <DynamicProvider element={element} />
    </AppProvider>
  );
};
