import React, { useContext, useEffect, useState } from 'react';

import LeftFast from '../../images/LeftFast.svg';
import RightFast from '../../images/RightFast.svg';
import PlayButton from '../../images/Vector.svg';
import Volume from '../../images/Volume.svg';
import Close from '../../images/Close.svg';
import MusicSlider from './MusicSlider';
import VolumeSlider from './VolumeSlider';
import { AppContext } from '../../apollo/wrap-root-element';

const AudioPlayer = () => {
  const { selectedPodCast, setSelectedPodcast, isAudioPause, SetIsAudioPause } = useContext(AppContext);
  const [audio, setAudio] = useState(null);

  const [progressbarWidth, setProgressBarWidth] = useState(0);
  const [totalDuration, setTotalDurations] = useState('00:00');
  const [currentTime, setCurrentTime] = useState('00:00');

  const onCrossClicked = () => {
    setSelectedPodcast(null);
  };

  const fetchAudioSrcAndSetAudio = () => {
    const podcastShareId = selectedPodCast.data.podcastId[0].text;
    const audioElement = document.getElementById('audio');
    fetch(`${process.env.GATSBY_LINEAGE_BACKEND_URL}api/podcasts/${podcastShareId}`)
      .then((response) => response.text())
      .then((data) => {
        const { payload } = JSON.parse(data);
        audioElement.src = payload?.audioUrl;
        setAudio(audioElement);
      })
      .catch((err) => {
        console.warn('Something went wrong.', err);
      });
  };

  useEffect(() => {
    setAudio(null);
    fetchAudioSrcAndSetAudio();
  }, [selectedPodCast]);

  useEffect(() => {
    if (!isAudioPause) {
      audio?.play();
    } else {
      audio?.pause();
    }
  }, [isAudioPause, audio]);

  useEffect(() => {
    if (selectedPodCast && audio) {
      SetIsAudioPause(false);
      audio.volume = 0.5;
      audio.currentTime = 0;
      audio?.play();
    }
  }, [selectedPodCast, audio]);

  useEffect(() => {
    if (audio) {
      const handleLoadedMetadata = () => {
        const duration = audio.duration;
        if (!isNaN(duration)) {
          const minutes = Math.floor(duration / 60);
          const seconds = Math.floor(duration % 60);
          const formattedDuration = `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
          setTotalDurations(formattedDuration);
        }
      };

      audio.addEventListener('loadedmetadata', handleLoadedMetadata);

      return () => {
        audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
      };
    }
  }, [audio]);

  const updateAudioCurrentTime = (time) => {
    audio.currentTime += time;
  };

  const onTimeUpdate = () => {
    if (audio) {
      const currentTime = audio.currentTime;
      const duration = audio.duration;
      const progressPercent = (currentTime / duration) * 100;

      // Update current time display
      const currentMinutes = Math.floor(currentTime / 60);
      const currentSeconds = Math.floor(currentTime % 60);
      setCurrentTime(
        `${currentMinutes < 10 ? '0' : ''}${currentMinutes}:${currentSeconds < 10 ? '0' : ''}${currentSeconds}`
      );

      setProgressBarWidth(progressPercent);
    }
  };

  const setProgress = (e) => {
    const clickX = e.target.value;
    const duration = audio.duration;
    audio.currentTime = (duration / 100) * clickX;
  };

  const toggleAudioPausePlay = () => {
    SetIsAudioPause(!isAudioPause);
  };

  const onVolumeChange = (e) => {
    audio.volume = e.target.value / 100;
  };

  return (
    <>
      <audio
        id="audio"
        controls
        style={{ display: 'none' }}
        onTimeUpdate={onTimeUpdate}
      >
        Your browser does not support the
        <code>audio</code> element.
      </audio>
      <div className="audio-player">
        <div className="player">
          <div className="music-slider">
            <MusicSlider onChange={setProgress} value={progressbarWidth} />
          </div>
          <span className="duration-text">{currentTime}</span>
          <span className="duration-text">{totalDuration}</span>
        </div>
        <div className="audio-details-container">
          <div className="audio-details">
            <img
              src={selectedPodCast?.data?.miniPlayerThumbNail?.url}
              alt="podcast"
              style={{ borderRadius: '5px', width: '50px', height: '50px' }}
            />
            <div
              style={{
                paddingLeft: '20px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <p
                title={selectedPodCast?.data?.podCastTitle[0].text}
                className="title"
              >
                {selectedPodCast?.data?.podCastTitle[0].text}
              </p>
              <p className="subtitle">
                {selectedPodCast?.data?.season.uid.replace(/-/g, ' ')}
              </p>
            </div>
          </div>
          <div className="audio-controls">
            <div>
              <button onClick={() => updateAudioCurrentTime(-15)}>
                <img className="left-speed" src={LeftFast} alt="left" />
              </button>
              <button onClick={toggleAudioPausePlay}>
                {isAudioPause ? (
                  <div className="play-icon">
                    <img src={PlayButton} alt="play" />
                  </div>
                ) : (
                  <div className="pause-icon"></div>
                )}
              </button>
              <button onClick={() => updateAudioCurrentTime(15)}>
                <img className="right-speed" src={RightFast} alt="right" />
              </button>
            </div>

            <div className="volume-cross-container">
              <img src={Volume} alt="volume" className="volume-icon" />
              <div className="volume-slider">
                <VolumeSlider onChange={onVolumeChange} />
              </div>
              <div className="cross-mark">
                <button
                  style={{
                    backgroundColor: '#F1F1F1',
                    padding: '4px',
                    marginTop: 'auto',
                    borderRadius: '4px',
                  }}
                  title="exit"
                  onClick={onCrossClicked}
                >
                  <img src={Close} alt="close" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AudioPlayer;
